
import { defineComponent, onMounted, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";

interface FormCatalog {
  id?: number;
  name: string;
  code: string;
}

export default defineComponent({
  name: "MaritimeServicesForm",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const submitButton = ref<HTMLElement | null>(null);
    const form = ref<FormCatalog>({
      name: "",
      code: "",
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    onMounted(() => {
      if (props.entityId) {
        ApiService.get("/api/maritime-services/" + props.entityId).then(
          ({ data }) => {
            form.value = { ...data };
          }
        );
      }
    });

    const createElement = async (resetForm) => {
      try {
        const resp = (
          await ApiService.post("/api/maritime-services", form.value)
        ).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (resetForm) => {
      ApiService.put("/api/maritime-services/" + props.entityId, form.value)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateElement(resetForm);
        } else {
          createElement(resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      onSumbit,
      isNameRequired,
      updateElement,
      createElement,
    };
  },
});
